import React from "react";
import Header from "../common/header";
import styled from "styled-components";
import {
  PressReleaseData,
  FinancialInstitution,
  BackedBy,
  WhatYouGetAsPartnerRipplingContent,
  FeedbackDataV2,
} from "../../data/homeData";
import CustomerFeedback from "../feedback/customerFeedback";
import RipplingHero from "./RipplingHero";
import RipplingFinancialLive from "./RipplingFinancialLive";
import Layout from "../layouts/layout";
import FooterV2 from "../partner/core/footerV2";
import LogoBarV2 from "../partner/core/logoBarV2";
import AIHumanSecretV2 from "../partner/core/AIHumanSecretV2";
import PartnerContactUsV2 from "../partner/core/PartnerContactUsV2";
import WhatYouGetAsPartner from "../partner/core/WhatYouGetAsPartner";
import { parseReqParams } from "../../utils/commonUtil";
import CurveTopBg from "../../images/home/gray-curve-top-bg.png";
import { Breakpoints, Spacing } from "../../styles/styleConstants";
import { QUERY_PARAMS, RIPPLING_CALENDLY_LINK } from "../../utils/commonConst";

export default function RipplingMain(props) {
  const signupCode = parseReqParams(QUERY_PARAMS.SIGNUP_CODE);

  return (
    <Layout includeHeader={false} includeFooter={false}>
      <HeroWrapper>
        <Header />
        <RipplingHero signupCode={signupCode} />
      </HeroWrapper>
      <LogoBarV2
        content={PressReleaseData}
        signupCode={signupCode}
        style={{ padding: "48px 0px 40px" }}
      />
      <WhatYouGetAsPartner
        whatYouGetAsPartnerData={WhatYouGetAsPartnerRipplingContent}
      />
      <LogoBarV2
        content={FinancialInstitution}
        signupCode={signupCode}
        style={{ padding: "32px 0px 0px" }}
      />
      <FinancialLivesWrapper>
        <RipplingFinancialLive />
      </FinancialLivesWrapper>
      <BackedBySectionWrapper>
        <LogoBarV2 content={BackedBy} signupCode={signupCode} />
      </BackedBySectionWrapper>
      <AIHumanSecretV2 />
      <CustomerFeedbackWrapper>
        <CustomerFeedback
          feedbackData={FeedbackDataV2}
          hidePricingButton={true}
          getStartedButtonLabel="Redeem financial plan"
        />
      </CustomerFeedbackWrapper>
      <PartnerContactUsV2
        headerText={"Let’s work together 🤍"}
        buttonName={"Schedule a call"}
        buttonLinkTarget={RIPPLING_CALENDLY_LINK}
      />
      <FooterWrapper>
        <FooterV2 />
      </FooterWrapper>
    </Layout>
  );
}

const HeroWrapper = styled.div`
  position: relative;

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const FooterWrapper = styled.div`
  background-color: white;
  padding-top: ${Spacing.xl};
  background: white url(${CurveTopBg}) top center no-repeat;
  background-size: 100%;
`;

const FinancialLivesWrapper = styled.div`
  background-color: #ebefff;
  margin-top: ${Spacing.xl};
  padding: 96px 0 ${Spacing.xxxxl};

  @media (max-width: ${Breakpoints.sm}) {
    margin-top: 0;
    padding: 24px 0 0;
  }
`;

const BackedBySectionWrapper = styled.div`
  margin: ${Spacing.xxl} 0 0;
`;

const CustomerFeedbackWrapper = styled.div`
  padding-top: ${Spacing.xxxxxl};
`;

import React from "react";
import styled from "styled-components";
import { Container, Col } from "react-bootstrap";

import Image from "../common/Image";
import CtaButton from "../common/CtaButton";
import HeaderText from "../common/HeaderText";
import { Breakpoints, Spacing } from "../../styles/styleConstants";
import { composeReqParam, navigateTo } from "../../utils/commonUtil";
import {
  APP_QUERY_PARAMS,
  HEADER_LEVEL,
  RIPPLING_CALENDLY_LINK,
  PAGE_NAME,
} from "../../utils/commonConst";

export default function RipplingHero() {
  return (
    <HeroWrapper>
      <Container className="partner-hero-container">
        <Col className="text-container">
          <LogoHeader>
            <Image
              className="uprise-logo"
              src="rippling/uprise-logo-grey.png"
            />
            <Image className="plus-icon" src="rippling/plus-icon-grey.png" />
            <Image
              className="rippling-logo"
              src="rippling/rippling-logo-grey.png"
            />
          </LogoHeader>
          <HeaderText level={HEADER_LEVEL.LEVEL_1}>
            Modern <TextHighlight>financial&nbsp;wellness</TextHighlight> for
            your employees.
          </HeaderText>
          <HeaderText
            style={{ margin: `${Spacing.lg} 0` }}
            level={HEADER_LEVEL.LEVEL_4_1}
          >
            Offer comprehensive financial wellness and advising to your team,
            covering everything from equity to benefits.
          </HeaderText>
          <CtaWrapper>
            <CtaButton
              onClick={(evt) => {
                navigateTo(
                  RIPPLING_CALENDLY_LINK,
                  composeReqParam(APP_QUERY_PARAMS.SOURCE, PAGE_NAME.PARTNER)
                );
                evt.preventDefault();
                evt.stopPropagation();
              }}
              ctaType="primary"
            >
              Schedule a call
            </CtaButton>
          </CtaWrapper>
        </Col>
      </Container>
      <Col className="pr-0">
        <Image className="HeroImage" src="partner/img-header-rippling.png" />
      </Col>
    </HeroWrapper>
  );
}

const HeroWrapper = styled.div`
  width: 100% !important;
  display: flex;
  margin-top: 24px;
  justify-content: center;
  align-items: center;
  .HeroImage {
    width: 100%;
  }

  .partner-hero-container {
    width: 50%;
    padding-right: 0 !important;
  }

  .text-container {
    padding-left: 15vw !important;
    @media (max-width: 1620px) {
      padding-left: 13vw !important;
    }
    @media (max-width: 1440px) {
      padding-left: 10vw !important;
    }
    @media (max-width: ${Breakpoints.lg}) {
      padding-left: 13vw !important;
    }
    @media (max-width: ${Breakpoints.md}) {
      padding-left: 13vw !important;
    }
    @media (max-width: ${Breakpoints.sm}) {
      margin-right: ${Spacing.md} !important;
      padding-left: ${Spacing.md} !important;
    }
  }

  @media (max-width: ${Breakpoints.sm}) {
    margin: 24px 0px;
    flex-direction: column;

    .partner-hero-container {
      width: 100%;
      padding: 0 !important;
    }
  }
`;

const LogoHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${Spacing.sm};
  margin-bottom: ${Spacing.lg};

  .uprise-logo {
    height: 28px;
    width: 112px;
  }

  .plus-icon {
    height: 13px;
    width: 13px;
  }

  .rippling-logo {
    height: 24px;
    width: 168px;
  }
`;

const CtaWrapper = styled.div`
  display: flex;
  margin: ${Spacing.lg} 0;
  gap: ${Spacing.lg};

  @media (max-width: ${Breakpoints.sm}) {
    flex-direction: column;
  }
`;

const TextHighlight = styled.span`
  position: relative;
  min-height: 65px;
  z-index: 1;
  margin-bottom: ${Spacing.sm};
  ::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: ${Spacing.xs};
    z-index: -1;
    background: linear-gradient(29.68deg, #3c5eff 0%, #00f4de 84.39%);
  }

  @media (max-width: 768px) {
    ::after {
      bottom: -4px;
    }
  }
`;

import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";

import Image from "../common/Image";
import HeaderText from "../common/HeaderText";
import { HEADER_LEVEL } from "../../utils/commonConst";
import { Breakpoints, Spacing } from "../../styles/styleConstants";

function RipplingFinancialLive() {
  return (
    <PocketWrapper>
      <Container className="financial-live-container">
        <HeaderText level={HEADER_LEVEL.LEVEL_1}>
          The concierge for your
          <br /> employees’ financial lives.
        </HeaderText>
        <HeaderText
          style={{ margin: `${Spacing.lg} 0` }}
          level={HEADER_LEVEL.LEVEL_4_1}
        >
          Comprehensive financial planning and anytime messaging with a
          financial advisor.
        </HeaderText>
        <Row className="image-row">
          <Col md={12} lg={7}>
            <Image
              src="rippling/img-concierge-rippling-1.png"
              style={{ width: "100%" }}
              imgStyle={{ objectFit: "contain" }}
            />
          </Col>
          <Col md={12} lg={5}>
            <Image
              src="rippling/img-concierge-rippling-2.png"
              style={{ width: "100%" }}
              imgStyle={{ objectFit: "contain" }}
            />
          </Col>
        </Row>
      </Container>
    </PocketWrapper>
  );
}

export default RipplingFinancialLive;

const PocketWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: ${Breakpoints.sm}) {
    .financial-live-container {
      padding: 0px ${Spacing.md} ${Spacing.lg} !important;
    }
    .image-row {
      gap: ${Spacing.lg};
    }
  }
`;
